import React, { useEffect, useState, memo } from 'react';
import { css } from '@emotion/react';

import {
  ErrorIcon,
  InputStyled,
  InputWrapper,
  MaskedInputStyled,
  PreffixIcon,
  SuffixIcon,
  Title,
  Wrapper,
} from './styles';

import { ComponentInputProps, MaskInputProps, SuffixIconTitleMemo } from './models';

const SuffixTitleIcon = memo(({ content }: SuffixIconTitleMemo) => {
  if (!content) {
    return null;
  }

  return content;
});

const Input = ({
  children,
  className,
  disabled,
  errorMessage,
  guide,
  id,
  iframe,
  mask,
  maxLength,
  onBlur,
  onChange,
  onFocus,
  pattern,
  placeholder,
  placeholderChar,
  placeholderPosition,
  prefixIcon,
  required,
  srLabelInput,
  srLabelSuffixIcon,
  suffixIcon,
  suffixIconCallback,
  suffixIconTitle,
  suffixIconType,
  title,
  touchedInput,
  type,
  value,
  borderColor,
  textColor,
  inputMode,
  isWarning
}: ComponentInputProps) => {
  const [focusTokenex, setFocusTokenex] = useState(false);

  useEffect(() => {
    iframe?.on('focus', () => {
      setFocusTokenex(true);
    });

    iframe?.on('blur', () => {
      setFocusTokenex(false);
    });

    return () => iframe?.remove();
  }, [iframe]);

  const renderInput = (maskParam: MaskInputProps) => {
    if (maskParam) {
      return (
        <MaskedInputStyled
          aria-label={srLabelInput}
          mask={mask}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={placeholder}
          required={required}
          type={type}
          inputMode={inputMode}
          placeholderChar={placeholderChar}
          pattern={pattern}
          onFocus={onFocus}
          keepCharPositions={true}
          showMask={false}
          guide={guide}
          value={value}
          maxLength={maxLength}
        />
      );
    }

    return (
      <InputStyled
        id={id}
        aria-label={srLabelInput}
        maxLength={maxLength}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        onFocus={onFocus}
        required={required}
        type={type}
        value={value}
        disabled={disabled}
        className={(disabled && 'bg-gray-200') || ''}

      />
    );
  };

  return (
    <Wrapper
      className={className}
      error={errorMessage}
      existsTitle={!!title}
      focus={focusTokenex}
      touched={touchedInput}
      textColor={textColor}
      borderColor={borderColor}
    >
      {title && (
        <Title>
          {title} <SuffixTitleIcon content={suffixIconTitle} />
        </Title>
      )}

      <InputWrapper
        className={(disabled && 'bg-gray-200') || ''}
        placeholderPosition={placeholderPosition}
        borderColor={borderColor}
        textColor={textColor}
      >
        <div>
          {prefixIcon && <PreffixIcon>{prefixIcon}</PreffixIcon>}

          {children ?? renderInput(mask)}

          {suffixIcon && (
            <SuffixIcon
              aria-label={srLabelSuffixIcon}
              type={suffixIconType}
              className="absolute"
              onClick={suffixIconCallback}
            >
              {suffixIcon}
            </SuffixIcon>
          )}

          {!suffixIcon && touchedInput && typeof errorMessage === 'string' && errorMessage?.length > 0 && (
            <ErrorIcon className="absolute">
              <span className="icon-exclamation-triangle" />
            </ErrorIcon>
          )}
        </div>

        {touchedInput && typeof errorMessage === 'string' && errorMessage.length > 0 && (
          <>
            {suffixIcon && (
              <ErrorIcon inline>
                <span className={isWarning ? 'icon-exclamation-triangle-yellow' : 'icon-exclamation-triangle'} />
              </ErrorIcon>
            )}
            <p
              className={`${isWarning ? 'text-yellow-custom'  : 'text-red'} text-xs leading-none`}
              css={css`
                padding-top: 0.55rem;
              `}
            >
              {errorMessage}
            </p>
          </>
        )}
      </InputWrapper>
    </Wrapper>
  );
};

Input.defaultProps = {
  guide: true,
  suffixIconType: 'button',
  title: undefined,
  type: 'text',
  value: '',
};

export { Input };
