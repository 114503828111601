import styled from '@emotion/styled';
import { css } from '@emotion/react';
import tw from 'twin.macro';
import MaskedInput from 'react-text-mask';
import { PlaceholderProps, PlaceholderPosition } from './models';

const blueWrapperField = css`
  ${tw`border border-blue`}

  div {
    ${tw`text-blue`}
  }
`;

const InputWrapper = styled.div`
  ${tw`border border-gray-400 p-1 rounded-md relative h-10`}
  transition: all 60ms;
  ${({ height }: any) => height && `height: ${height};`}

  &:focus-within {
    ${({ borderColor }: PlaceholderProps) => (borderColor ? `border-color: ${borderColor};` : blueWrapperField)}
  }

  & > div {
    ${tw`flex h-8`}
  }

  & input {
    &::placeholder {
      ${({ placeholderPosition }: PlaceholderProps) =>
        (({
          left: tw`text-left`,
          default: tw`text-center`,
        } as PlaceholderPosition)[placeholderPosition ?? 'default'])}
    }
  }
`;

const Title = styled.p`
  max-width: max-content;
  ${tw`flex justify-center items-center bg-white text-gray-800 text-sm leading-3 items-center px-1 pb-2 text-gray-800 font-thin`}
`;

const SuffixIcon = styled.button`
  right: 0.5rem;
  top: 55%;
  transform: translateY(-50%);
  -webkit-tap-highlight-color: transparent;
  ${tw`cursor-pointer flex justify-center items-center px-1 py-2`}

  &:focus {
    ${tw`outline-none`}
  }
`;

const ErrorIcon = styled.span<{ inline?: boolean }>`
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  ${tw`cursor-pointer`}

  ${({ inline }) =>
    inline &&
    css`
      & + p,
      & > span {
        display: block;
        margin-bottom:1px
        @media (max-width: 360px) {
          font-size: 0.7rem;
          line-height: 1.5em;
        }
      }

      & > span {
        ${tw`float-left mr-1`}
        margin-top: 5px;

        &::before {
          font-size: 0.9rem;
        }
      }
    `}
`;

const PreffixIcon = styled.div`
  ${tw`px-3 flex justify-center items-center bg-transparent`}
`;

const InputStyled = styled.input`
  ${tw`w-full p-1 outline-none`}
  font-size: 16px;
`;

const MaskedInputStyled = styled(MaskedInput)`
  ${tw`w-full p-1 outline-none`}
  font-size: 16px;
`;

const Wrapper = styled.div`
  ${({ focus, existsTitle }: any) => css`
      ${
        !existsTitle &&
        css`
          align-items: end;
          display: grid;
        `
      }}

      p {
        ${focus && tw`text-blue`}
        
    }
    
    & > div {
        ${focus && tw`border border-blue`}
      }
    `}
  }

  &:focus-within {
    p {
      ${({ textColor }: any) => (textColor ? `color: ${textColor};` : tw`text-blue`)}
    }
  }

  ${({ touched, error, borderColor }) =>
    touched &&
    error &&
    css`
      & > div {
        ${borderColor ? `border-color: ${borderColor};` : tw`border border-red`}
      }
    `}

  ${({ touched, error, textColor, borderColor }) =>
    touched &&
    !error &&
    css`
      & > div {
        ${borderColor ? `border-color: ${borderColor};` : tw`border border-blue`}
      }

      p {
        ${textColor ? `color: ${textColor};` : tw`text-blue`}
      }
    `}
`;

export { InputWrapper, Title, SuffixIcon, PreffixIcon, InputStyled, MaskedInputStyled, Wrapper, ErrorIcon };
